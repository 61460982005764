$(document).ready(function () {

    var videoUrl = '//www.youtube.com/embed/twr27o2l4og?rel=0&autoplay=1';

    $('.menu-item-has-children')
        .mouseenter(function() {
            $(this).find('ul.sub-menu').show();
        })
        .mouseleave(function() {
            $(this).find('ul.sub-menu').hide();
        });

    $('.main-slideshow .slideshow').carouFredSel({
        responsive: true,
        auto: {
            play: true,
            timeoutDuration: 4000
        },
        width: '100%',
        items: {
            visible: 1,
            height: 480
        },
        scroll: {
            easing: 'quadratic',
            duration: 1200,
            pauseOnHover: false
        },
        prev: '.main-slideshow .prev',
        next: '.main-slideshow .next',
        pagination: '.main-slideshow .pagination'

    });

    $('.video-layout a').click(function (event) {
        event.preventDefault();
        $('.video-layout')
            .hide()
            .find('iframe')
            .attr('src', '#');
    });

    $('.view-video').click(function (event) {
        event.preventDefault();
        $('.video-layout')
            .show()
            .find('iframe')
            .attr('src', videoUrl);
    });

    $('.ref ul').carouFredSel({
        responsive: true,
        auto: {
            play: true,
            timeoutDuration: 1000
        },
        width: '100%',
        scroll: 1,
        items: {
            width: 100,
            height: 120,
            visible: {
                min: 1,
                max: 5
            }
        },
        prev: '.ref .prev',
        next: '.ref .next'
    });

    $('.more').click(function (event) {
        event.preventDefault();
        //$('.subparts .close').trigger('click');
        $(this)
            .hide()
            .parent()
            .parent()
            .find('.subparts')
            .show();
    });


    var History = window.History;
    var State = null;
    var originalTitle = document.title;

    /*History.Adapter.bind(window,'statechange',function() {
     State = History.getState();
     document.title = originalTitle+" - "+$('a[data-scrollto="'+State.data.anchor+'"]').attr('title');
     if (State.data.scroll) {
     $.scrollTo(State.data.anchor,  1200, { offset: {top: -104} });
     };
     });*/


    /*$('.modules section').each(function(){
     var position = $(this).position();
     $(this).scrollspy({
     min: position.top - 300,
     max: (position.top - 300) + $(this).height(),
     onEnter: function(element, position){
     var $link = $('a[data-scrollto="#'+element.id+'"]');
     $link.parents('li').addClass('current').siblings('li').removeClass('current');
     History.pushState({anchor: '#'+element.id, scroll: false}, document.title, $link.attr('href'));
     }
     });
     });*/

    /*$('.lst-modules li a').on('click', function(event) {
     event.preventDefault();
     var anchor = $(this).data('scrollto');
     History.pushState(
     {
     anchor: anchor,
     scroll: true
     },
     document.title,
     $(this).attr('href')
     );
     });*/

    if (typeof currentModuleSlug !== 'undefined') {
        History.pushState({anchor: '#' + currentModuleSlug, scroll: true}, document.title, null);
    }
    ;

    function closeSubParts(event) {
        if (event) event.preventDefault();
        $(this)
            .parent()
            .parent()
            .hide()
            .prev()
            .find('.more')
            .show();
    }

    $('.subparts .close').click(closeSubParts);

    $('.modules .toggle').click(function (event) {
        event.preventDefault();
        $(this)
            .parent()
            .hide();
        $(this)
            .parent()
            .parent()
            .find('.module-content')
            .show();
    });

    $('.what-use .slides-layout li').click(function (event) {
        $(this)
            .parent()
            .find('.active')
            .removeClass('active');
        $(this).addClass('active');
    });
    
    /*************************************************************************** Contact buttons */
    
    /* hide forms inside .contact-layout */
    function hideForms() {
        $('.form-ctnr').each(function () {
            $(this).hide();
        });
    }
    
    function hideMenuIfMobile() {
        if ($('#slideMenu').css('display') == 'block') $('#slideMenu').hide();
        $('button#menu').removeClass('active');
    }
    
    // Bouton de contact du header, on recupere headerContactId depuis footer.php
    // pour ouvrir le formulaire selon la langue
    $('header a[href*="contact"], #contact-mobile').click(function (event) {
        event.preventDefault();
        hideForms();
        $('.contact-layout').fadeIn();
        $('#' + headerContactId + '').show();
        if ($('#ismobile').css('display') === 'block') {
            hideMenuIfMobile();
        }
    });
    
    $('.close-contact').click(function (event) {
        event.preventDefault();
        $('.contact-layout').hide();
        ///$('.contact-content').show();
        //$('.contact-layout form').show().get(0).reset();
        //$('.contact-layout .confirm').hide();
        hideForms();
    });
    
    if ($('#ismobile').length) {
        //Au clic sur le menu
        $('#menu').on('click', function () {
            //On vérifie que le bouton est bien visible (= qu'on en version mobile)
            $(this).toggleClass('active');
            if ($('#ismobile').css('display') == 'block') {
                $('#slideMenu').toggle();
                //Si le bloc contact est visible, on le cache
                if ($('.contact-layout').css('display') == 'block') {
                    $('.contact-layout').hide();
                    hideForms();
                }
            }
        });
    }
    
    /*************************************************************************** Contact bar footer */
    
    
    if ($('#contact_bar').length > 0) {
        var mailField = document.querySelector('#contact_bar .your-email');
    
        mailField.addEventListener('click', function (e) {
            if (e.target.localName === 'span') {
                e.target.style.display = 'none';
            }
        });
    
        var trigger, formCtnr;
        // On recupere la liste des posts depuis footer.php si elle existe
    
        for (var postId in postsArray) {
            /* Closure !! */
            (function (postId) {
                trigger = postId + '-trigger';
                trigger = document.getElementById(trigger);
                trigger.addEventListener('click', function (event) {
                    event.preventDefault();
                    formCtnr = document.querySelector('#' + postId + '-form-ctnr');
                    hideForms();
                    $('.contact-layout').fadeIn();
                    $(formCtnr).show();
                    hideMenuIfMobile();
                    if ($('#ismobile').css('display') === 'block') {
                        $('body').animate({'scrollTop': 0});
                    }
                });
            })(postId);
        }
        
        var contactBar = $('#contact_bar'),
            windo = $(window);
        
        windo.on('scroll', function(){
            if (windo.scrollTop() > 55) {
                contactBar.removeClass('contact_bar-initial').addClass('contact_bar-scrolled');
            } else {
                contactBar.removeClass('contact_bar-scrolled').addClass('contact_bar-initial');
            }
        });
    }

    /*$('a[href*="contactez-nous"], a[href*="contact-us"]').click(function(event){
    	event.preventDefault();
    	$('.contact-layout').show();
    });

    $('.close-contact').click(function (event) {
        event.preventDefault();
        $('.contact-layout').hide();
        $('.contact-content').show();
        $('.contact-layout form').show().get(0).reset();
        $('.contact-layout .confirm').hide();
    });
    if ($('.wpcf7-form-control.wpcf7-submit').length) {
        $('.wpcf7-form-control.wpcf7-submit').on('click', function () {
            $('html').animate({scrollTop: 0});
        })
    }*/
    /*
     $('.contact-layout input[type="submit"]').click(function(event){
     event.preventDefault();

     var flag = true;
     $('.contact-layout [required]').each(function(){
     if($(this).val().length == 0) flag = false;
     });

     if(!flag) return alert('Les champs marqués d\'un astérisque sont obligatoires.');

     var $form = $('.contact-layout form');

     $.post($form.attr('action'), $form.serialize(), function(response){
     $form
     .hide()
     .get(0)
     .reset();
     $('.contact-content').hide();

     $('.contact-layout .confirm').show();
     });
     });
     */


    /*if ($('#ismobile').length) {
        //Au clic sur le menu
        $('#menu').on('click', function () {
            //On vérifie que le bouton est bien visible (= qu'on en version mobile)
            $(this).toggleClass('active');
            if ($('#ismobile').css('display') == 'block') {
                $('#slideMenu').toggle();
                //Si le bloc contact est visible, on le cache
                if ($('.contact-layout').css('display') == 'block') {
                    $('.contact-layout').hide();
                }
            }
        });
    }*/


    var $container = $('#list_article_masonry');
    // initialize
    $container.masonry({
        itemSelector: '.item'
    });

    jQuery('#medpost').on('mouseenter', 'li', function () {
        jQuery(this).find('img.main-single,img.attachment-3c-image-slider').stop(true, true).animate({'opacity': '.75'}, {duration: 350});
        jQuery(this).find('img.imgformat').stop(true, true).animate({'opacity': '0.3'}, {duration: 350});
        jQuery(this).find('.entry').stop(true, true).animate({'opacity': '1'}, {duration: 350});
        jQuery(this).find('.meta').stop(true, true).animate({'opacity': '.7'}, {duration: 350});

    });

    jQuery('#medpost').on('mouseleave', 'li', function () {
        jQuery(this).find('img.main-single,img.attachment-3c-image-slider').stop(true, true).animate({'opacity': '1'}, {duration: 400});
        jQuery(this).find('img.imgformat').stop(true, true).animate({'opacity': '1'}, {duration: 350});
        jQuery(this).find('.entry').stop(true, true).animate({'opacity': '.6'}, {duration: 400});
        jQuery(this).find('.meta').stop(true, true).animate({'opacity': '.4'}, {duration: 400});
    });

    /*************************************************************************** TWEETFEED */
    if ($('.tweet-feed').length > 0) {

        $('.tweet-feed-list').twittie({
            dateFormat: '%b. %d, %Y',
            username: 'AdvocacyExpert',
            template: '<h4><strong>Advocacy Experts</strong><a href="http://www.twitter.com/AdvocacyExpert" title="Advocacy Experts" target="_blank" class="user">@AdvocacyExpert</a></h4>{{tweet}}',
            count: 3,
            apiPath: theme_url + '/tweetie-master/api/tweet.php',
            loadingText: 'Chargement...'
        });
    }

    /*************************************************************************** MODULES (AJAX) */
    if ($('#page-single-module').length > 0) {

        $('#single-modules .nav').each(function () {
            $(this).find('a').parent().addClass('active');
        });

        /* a l'ouverture de la page, mettre la classe active sur l'item courant */
        $('#page-single-module a').each(function () {
            if (window.location.href == $(this).attr('href')) {
                $(this).addClass('active');
            }
        });


        var dontclick = false;
        /*
         ***********************************************************
         Ajax sur la page des modules - abandonné because plugin seo
         ***********************************************************
        $('body').delegate('#page-single-module a, .nav a', 'click', function (event) {

            event.preventDefault();
            
            var url = $(this).attr('href');
            var datalink, datalink_, transition;
            if (dontclick) {
                return false;
            }

            dontclick = true;

            $('#page-single-module a').each(function () {
                if ($(this).hasClass('active'))
                    datalink_ = parseInt($(this).attr('data-link'));
            });

            if ($(this).attr('data-link')) { // si je clic sur un item de la liste des modules
                datalink = $(this).attr('data-link');
                if (datalink < datalink_) transition = '-500';
                else transition = '500';

            } else { // sur les fleches droites - gauches
                datalink = datalink_;
                if ($(this).parent().hasClass('nav-left')) {
                    datalink--;
                    transition = '-500';
                } else if ($(this).parent().hasClass('nav-right')) {
                    datalink++;
                    transition = '500';
                }
            }
            $.ajax({
                url: url,
                type: 'POST',
                dataType: 'html',
                header: {
                    'X-Requested-With': 'BAWXMLHttpRequest'
                }
            }).done(function (data) {
                dontclick = false;

                $('#page-single-module a').each(function () {
                    $(this).removeClass('active');
                    if ($(this).attr('data-link') == datalink)
                        $(this).addClass('active');
                });

                // sliding animation
                $("#single-modules .change").animate({
                    opacity: 0,
                    marginLeft: transition
                }, 600, function () {

                    $("#single-modules .change ").html($(data).find('#single-modules .change >'));

                    $("#single-modules .change").css("margin-left", -transition);
                    $("#single-modules .change").animate({
                        opacity: 1,
                        marginLeft: 0,
                        marginRigth: 0
                    }, 600);
                    $('#single-modules .nav').each(function () {
                        $(this).find('a').parent().addClass('active');
                    });
                });
                if (url != window.location) {

                    window.history.pushState({path: url}, '', url);
                    var array = url.split('/');

                    var lastsegment = array[array.length - 2];
                    document.title = "Brand advocacy - " + lastsegment + " - Brand advocacy";

                }

                // remplacement de l'url du language switcher
                $('#page-single-module a').each(function(){
                    if($(this).attr('href') == url){
                        var currentPostTradUrl = $(this).attr('data-currenttrad');
                        $('.lang_selector a').not('.active').attr('href', currentPostTradUrl);
                    }
                });

            }).error(function (data) {
                console.log(data);
            });

            return false;
        });
         ***********************************************************
         Fin ajax
         ***********************************************************
         */
        $(window).on('load resize', function () {
            $('#module-content').css({width: '100%'}).css({width: $('#module-content').width()});
            $('.change').css({width: '100%'}).css({width: $('.change').width()});
        });

        /*$(document).on('click', '.list-modules.mobileonly nav a', function (event) {
            event.preventDefault();
            $(this).addClass('active').siblings().removeClass('active');
            $("html, body").animate({scrollTop: $('#module-' + $(this).data('module-id')).offset().top}, 600);
        });*/

        /*$('#page-single-module a.active').trigger('click');*/

    }


});